import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";

export const getSuppliers = createAsyncThunk(
  "suppliers/getSuppliers",
  async (data, { dispatch }) => {
    const { tokenVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const suppliers = await fetch(
        `${process.env.REACT_APP_API_URL}/getSuppliers`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getSuppliers failed");
        } else {
          return res.json();
        }
      });

      return flattenSuppliers(suppliers);
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const createSupplier = createAsyncThunk(
  "suppliers/createSupplier",
  async (data, { dispatch }) => {
    const { tokenVal, supplier } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newSupplier = await fetch(
        `${process.env.REACT_APP_API_URL}/createSupplier`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(supplier),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("createSupplier failed");
        } else {
          return res.json();
        }
      });

      return newSupplier;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updateSupplier = createAsyncThunk(
  "suppliers/updateSupplier",
  async (data, { dispatch }) => {
    const { tokenVal, supplier } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateSupplier`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(supplier),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("updateSupplier failed");
        }
      });

      return supplier;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const discontinueSupplier = createAsyncThunk(
  "suppliers/discontinueSupplier",
  async (data, { dispatch }) => {
    const { tokenVal, supplierId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/discontinueSupplier`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify({ supplierId }),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("discontinueSupplier failed");
        }
      });

      return supplierId;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const supplierSlice = createSlice({
  name: "suppliers",
  initialState: {
    suppliers: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [getSuppliers.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.suppliers = action.payload.entities.supplierList;
    },
    [getSuppliers.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getSuppliers.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [discontinueSupplier.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.suppliers[action.payload].discontinued = true;
    },
    [discontinueSupplier.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [discontinueSupplier.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

const flattenSuppliers = (result) => {
  const supplier = new schema.Entity(
    "supplierList",
    {},
    { idAttribute: "supplier_id" }
  );
  const supplierList = new schema.Array(supplier);

  const flattenedResult = normalize(result, supplierList);

  return flattenedResult;
};

export const supplierList = (state) => state.supplier?.suppliers;
export const isLoading = (state) => state.supplier.loading;
export const error = (state) => state.supplier.error;

export default supplierSlice.reducer;
