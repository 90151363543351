import React from "react";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

export const CasedGoodDetails = ({ casedGood }) => {
  return (
    <>
      <Typography>{casedGood.Category}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <List dense disablePadding>
              {Object.keys(casedGood).map((key) => {
                return (
                  // show a list item for any casedGood property we have a value for
                  casedGood[key] && (
                    <ListItem key={key}>
                      <ListItemText primary={key} secondary={casedGood[key]} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
