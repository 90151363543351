import React from "react";
import { Link } from "react-router-dom";
import {
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isLoading, windowList } from "./windowSlice";
import { ItemList } from "../items/ItemList";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    flexGrow: 1,
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const WindowList = (props) => {
  const classes = useStyles();
  const { windowIds, showHidden } = props;
  const loading = useSelector(isLoading);
  const roomWindows = useSelector(windowList);

  const windowsForRoom = windowIds.map((key) => roomWindows[key]);

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <>
        {windowsForRoom &&
          windowsForRoom.sort((a, b) =>
            a.name?.toLowerCase() > b.name?.toLowerCase()
              ? 1
              : b.name?.toLowerCase() > a.name?.toLowerCase()
              ? -1
              : 0
          ) &&
          windowsForRoom.map(
            (w) =>
              w.window_id && (
                <div key={w.window_id} className={classes.root}>
                  <Accordion TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.accordionHeading}>
                        {w.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-start"
                        >
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Add Items"
                            component={Link}
                            to={`/search?windowId=${w.window_id}`}
                            className={classes.buttonSpacing}
                          >
                            Add Items
                          </Button>
                          <Button
                            component={Link}
                            to={`/custom-item?windowId=${w.window_id}`}
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Add custom item"
                            className={classes.buttonSpacing}
                          >
                            Add Custom Item
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Edit Window"
                            component={Link}
                            to={`/window/edit/${w.window_id}`}
                            className={classes.buttonSpacing}
                          >
                            Edit Window
                          </Button>
                        </Grid>
                        {w.items.length > 0 ? (
                          <ItemList itemIds={w.items} showHidden={showHidden} />
                        ) : (
                          <Typography variant="body2">No items</Typography>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )
          )}
      </>
    );

  return renderComponent;
};
