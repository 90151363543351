export const getCustomItemDisplayName = (customItem) => {
  const fabric = customItem.cg.find((f) => f.cased_goods_category === "Fabric");

  const fabricName = fabric ? fabric.cased_goods_name : "COM";

  let size = "";

  switch (customItem.customProductName) {
    case "Pillow Sham":
    case "Bed Skirts":
    case "Duvet Cover":
    case "Coverlets":
    case "Bedspreads":
      size = customItem.cptf.find((v) => v.field_name === "Size").field_value;

      break;
    case "Drapery":
      const draperyWidth = customItem.cptf.find(
        (v) => v.field_name === "Rod Width"
      ).field_value;

      const draperyLength = customItem.cptf.find(
        (v) => v.field_name === "Finished Length"
      ).field_value;
      size = `${draperyWidth}" x ${draperyLength}"`;

      break;

    case "Throw Cushion":
      size =
        customItem.cptf.find((v) => v.field_name === "Size (in inches)")
          .field_value + '"';

      break;
    case "Seat Cushion":
      const cushionWidth = customItem.cptf.find(
        (v) => v.field_name === "Width (in inches)"
      ).field_value;

      const cushionLength = customItem.cptf.find(
        (v) => v.field_name === "Length (in inches)"
      ).field_value;
      size = `${cushionWidth}" x ${cushionLength}"`;

      break;
    case "Window and Bench Seats":
      size =
        customItem.cptf.find((v) => v.field_name === "Size (Left to Right)")
          .field_value + '"';

      break;
    default:
      break;
  }

  return `ICFY ~ ${customItem.customProductName} ~ ${size} ~ ${fabricName}`;
};

//remove unnecessary decimal and zeros from whole numbers
export const getQuantityFormat = (quantity) => {
  return (+quantity)
    .toFixed(2)
    .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
};
