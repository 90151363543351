import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  componentSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const HeaderTableCell = withStyles(() => ({
  root: {
    borderBottom: "none",
  },
}))(TableCell);

export const Header = ({ customItemData }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.componentSpacing}>
        <Table
          className={classes.table}
          aria-label="customer details header"
          padding="none"
        >
          <TableBody>
            <TableRow>
              <HeaderTableCell>
                <b>Decorator:</b>{" "}
                {`${customItemData.u.first_name} ${customItemData.u.last_name}`}
              </HeaderTableCell>
              <HeaderTableCell>
                <b>Date:</b>{" "}
                {new Date(
                  customItemData.o.order_date * 1000
                ).toLocaleDateString()}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <HeaderTableCell>
                <b>Customer Tag Name:</b> {customItemData.p.project_name}
              </HeaderTableCell>
              <HeaderTableCell>
                <b>PO:</b> {customItemData.o.order_id}
              </HeaderTableCell>
            </TableRow>
            <TableRow>
              <HeaderTableCell>
                <b>Room:</b> {customItemData.r.name}
              </HeaderTableCell>
              <HeaderTableCell>
                <b>Sew:</b>
              </HeaderTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
