import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Button,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isLoading, updateProjectCasedItem } from "./projectItemSlice";
import { token } from "../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";
import { Redirect } from "react-router-dom";
import { Currency } from "../../shared/currency/Currency";
import { getQuantityFormat } from "../../shared/Format";

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disclaimer: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
  },
}));

export const validForm = (fieldData) => {
  return fieldData.name;
};

export const ProjectItemDetails = (props) => {
  const { id } = props.match.params;

  const loading = useSelector(isLoading);
  const item = useSelector((state) => state.projectItems.itemData[id]);
  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);

  const [fieldData, setFieldData] = useState();
  const [invalidQuantity, setInvalidQuantity] = useState(false);

  const selectedInput = useRef();

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  });

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const updateItem = (statusId) => {
    dispatch(
      updateProjectCasedItem({
        tokenVal,
        updatedItemVal: { ...item, ...fieldData, status_id: statusId },
      })
    );
  };

  if (shouldRedirectVal === "project") {
    return <Redirect to={`/project/${item.project_id}`} />;
  }

  const isCustomItem = item.custom_product_id !== null;

  const itemIsHidden = item.status_id === 2;

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.titleText} noWrap>
            {item.name || item.customProductName}
          </Typography>
        </div>
        <Table>
          <TableBody>
            {isCustomItem ? (
              <>
                <TableRow>
                  <TableCell variant="head">Custom Item Type</TableCell>
                  <TableCell>{item.customProductName}</TableCell>
                </TableRow>
                {item.cptf.map(
                  (cptf) =>
                    cptf.field_name &&
                    cptf.field_value && (
                      <TableRow key={cptf.field_name}>
                        <TableCell variant="head">{cptf.field_name}</TableCell>
                        <TableCell>{cptf.field_value}</TableCell>
                      </TableRow>
                    )
                )}
                {item.cg.map(
                  (cg) =>
                    cg.cased_goods_name &&
                    cg.cased_goods_category && (
                      <TableRow key={cg.cased_goods_name}>
                        <TableCell variant="head">
                          {cg.cased_goods_category}
                        </TableCell>
                        <TableCell>
                          {cg.cased_goods_name}
                          {cg.cased_goods_colour &&
                            ` (${cg.cased_goods_colour})`}
                        </TableCell>
                      </TableRow>
                    )
                )}
                <TableRow>
                  <TableCell variant="head">Quantity</TableCell>
                  <TableCell>{getQuantityFormat(item.quantity)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Notes</TableCell>
                  <TableCell>{item.notes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Quote</TableCell>
                  <TableCell>
                    <Currency number={item.quote} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell variant="head">Description</TableCell>
                  <TableCell>{item.description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Supplier</TableCell>
                  <TableCell>{item.supplier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Category</TableCell>
                  <TableCell>{item.category}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Item Number</TableCell>
                  <TableCell>{item.item_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Unit of Measure</TableCell>
                  <TableCell>{item.unit_of_measure}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Retail Price</TableCell>
                  <TableCell>
                    <Currency number={item.retail_price} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Wholesale Price</TableCell>
                  <TableCell>
                    <Currency number={item.wholesale_price} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Shipping</TableCell>
                  <TableCell>{item.shipping}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>

        {!item.order_id && (
          <div>
            <Typography
              className={classes.disclaimer}
              variant="body2"
              paragraph={true}
            >
              Prices are subject to change until time of purchase
            </Typography>
          </div>
        )}

        <TextField
          variant="outlined"
          margin="normal"
          label="Quantity"
          type="number"
          defaultValue={getQuantityFormat(item.quantity)}
          error={invalidQuantity}
          helperText={invalidQuantity && "Quantity must be a whole number"}
          onChange={(e) => {
            //quantity must be a whole number for everything but fabric
            setInvalidQuantity(
              e.target.value % 1 !== 0 && item.category !== "Fabric"
            );
            setFieldData({ ...fieldData, quantity: e.target.value });
          }}
          required
          InputProps={{
            inputProps: {
              min: 1,
              step: item.category === "Fabric" ? 0.25 : 1,
            },
          }}
          disabled={item.order_id !== null}
        />
        {item.category === "Fabric" && (
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              label="Colour"
              defaultValue={item.colour}
              onChange={(e) =>
                setFieldData({ ...fieldData, colour: e.target.value })
              }
              required
              InputProps={{ inputProps: { maxLength: 200 } }}
              disabled={item.order_id !== null}
            />
          </div>
        )}
        {item.p_notes && (
          <div>
            <TextField
              className={classes.spacing}
              id="additional-details"
              label="Additional Details"
              multiline
              rows={4}
              defaultValue={item.pp_notes}
              placeholder={item.p_notes}
              variant="outlined"
              required
              onChange={(e) =>
                setFieldData({ ...fieldData, notes: e.target.value })
              }
            />
          </div>
        )}
        <div>
          <Button
            onClick={() => updateItem(1)}
            variant="contained"
            color="primary"
            aria-label="Save Item"
            className={classes.buttonSpacing}
            disabled={invalidQuantity || item.order_id !== null}
          >
            Save Changes
          </Button>
          <Button
            onClick={() => updateItem(itemIsHidden ? 1 : 2)}
            variant="contained"
            color="primary"
            aria-label="Hide Item"
            className={classes.buttonSpacing}
          >
            {itemIsHidden ? "Unhide" : "Hide"}
          </Button>
          <Button
            onClick={() => updateItem(3)}
            variant="contained"
            color="secondary"
            aria-label="Delete Item"
            className={classes.buttonSpacing}
            disabled={item.order_id !== null}
          >
            Delete
          </Button>
        </div>
      </>
    );

  return renderComponent;
};
