import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";

export const getSupplierOrders = createAsyncThunk(
  "admin/getSupplierOrders",
  async (data, { dispatch }) => {
    const { tokenVal, pdfGenerated, orderBy = "" } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const orderData = await fetch(
        `${process.env.REACT_APP_API_URL}/getSupplierOrders?pdfGenerated=${pdfGenerated}&orderBy=${orderBy}`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getSupplierOrders failed");
        } else {
          return res.json();
        }
      });

      return orderData;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getOrdersByStatusId = createAsyncThunk(
  "admin/getOrdersByStatusId",
  async (data, { dispatch }) => {
    const { tokenVal, orderStatusId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const orderData = await fetch(
        `${process.env.REACT_APP_API_URL}/getOrdersByStatusId/${orderStatusId}`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getOrdersByStatusId failed");
        } else {
          return res.json();
        }
      });

      return orderData;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getCustomItemById = createAsyncThunk(
  "admin/getCustomItemById",
  async (data, { dispatch }) => {
    const { tokenVal, customItemId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const customItem = await fetch(
        `${process.env.REACT_APP_API_URL}/getCustomItemById/${customItemId}`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getCustomItemById failed");
        } else {
          return res.json();
        }
      });

      return customItem;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "admin/updateOrderStatus",
  async (data, { dispatch }) => {
    const { tokenVal, orderStatusVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateOrderStatus`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(orderStatusVal),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("update failed");
        }
      });

      return orderStatusVal;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updateCustomItemsPdfGeneratedStatus = createAsyncThunk(
  "admin/updateCustomItemsPdfGeneratedStatus",
  async (data, { dispatch }) => {
    const { tokenVal, selectedWorkOrders } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/updateCustomItemsPdfGeneratedStatus`,
        {
          method: "PUT",
          mode: "cors",
          headers: headers,
          body: JSON.stringify(selectedWorkOrders),
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("updateCustomItemsPdfGeneratedStatus failed");
        }
      });

      return;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getUsers = createAsyncThunk(
  "admin/getUsers",
  async (data, { dispatch }) => {
    const { tokenVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const userData = await fetch(
        `${process.env.REACT_APP_API_URL}/getUsers`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getUsers failed");
        } else {
          return res.json();
        }
      });

      return userData;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getPendingUserCount = createAsyncThunk(
  "admin/getPendingUserCount",
  async (data, { dispatch }) => {
    const { tokenVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const pendingUserCount = await fetch(
        `${process.env.REACT_APP_API_URL}/getPendingUserCount`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getPendingUserCount failed");
        } else {
          return res.json();
        }
      });

      return pendingUserCount;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "admin/updateUser",
  async (data, { dispatch }) => {
    const { tokenVal, updatedUserVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateUserAdmin`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(updatedUserVal),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("update failed");
        }
      });

      return true;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getAdminOrderDetails = createAsyncThunk(
  "admin/getAdminOrderDetails",
  async (data, { dispatch }) => {
    const { tokenVal, orderId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const orderDetails = await fetch(
        `${process.env.REACT_APP_API_URL}/getOrderDetails/${orderId}`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              dispatch({ type: "invalidToken" });
              throw new Error("invalid token");
            }

            throw new Error("getAdminOrderDetails failed");
          } else {
            return res.json();
          }
        })
        .then((result) => {
          //organize it like this so normalizr can figure out the relationships
          return flattenOrderItems({
            order_id: orderId,
            order_notes: result.order_notes,
            user_id: result.user_id,
            orderItems: result.items,
          });
        });

      return { orderId, results: orderDetails };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getOrderCountsForAllStatuses = createAsyncThunk(
  "admin/getOrderCountsForAllStatuses",
  async (data, { dispatch }) => {
    const { tokenVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const orderCounts = await fetch(
        `${process.env.REACT_APP_API_URL}/getOrderCountsForAllStatuses`,
        {
          headers: headers,
        }
      ).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("getOrderCountsForAllStatuses failed");
        } else {
          return res.json();
        }
      });

      return orderCounts;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    adminOrderData: {},
    adminOrderItemData: {},
    adminCustomItemData: {},
    adminOrderCounts: [],
    userData: [],
    pendingUserCount: [],
    loading: "idle",
    error: null,
  },

  reducers: {
    clearOrderData: (state, action) => {
      state.adminOrderData = {};
    },
  },
  extraReducers: {
    [getSupplierOrders.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.adminOrderData = action.payload;
    },
    [getSupplierOrders.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getSupplierOrders.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getOrdersByStatusId.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.adminOrderData = action.payload;
    },
    [getOrdersByStatusId.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getOrdersByStatusId.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getCustomItemById.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.adminCustomItemData = action.payload;
    },
    [getCustomItemById.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getCustomItemById.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [updateOrderStatus.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
    },
    [updateOrderStatus.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [updateOrderStatus.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [updateCustomItemsPdfGeneratedStatus.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
    },
    [updateCustomItemsPdfGeneratedStatus.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [updateCustomItemsPdfGeneratedStatus.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.userData = action.payload;
    },
    [getUsers.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getUsers.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getAdminOrderDetails.fulfilled]: (state, action) => {
      const orderId = action.payload.results.result;
      const newOrderData = action.payload.results.entities.order[orderId];

      state.loading = "idle";
      state.error = "";
      state.adminOrderData[orderId] = {
        ...newOrderData,
        orderItems: newOrderData.orderItems,
      };
      state.adminOrderItemData = action.payload.results.entities.orderItems;
    },
    [getAdminOrderDetails.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getAdminOrderDetails.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getOrderCountsForAllStatuses.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.adminOrderCounts = action.payload;
    },
    [getOrderCountsForAllStatuses.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getOrderCountsForAllStatuses.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getPendingUserCount.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.pendingUserCount = action.payload;
    },
    [getPendingUserCount.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getPendingUserCount.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

const flattenOrderItems = (result) => {
  const orderItem = new schema.Entity(
    "orderItems",
    {},
    { idAttribute: "project_product_id" }
  );

  const flattenedOrder = new schema.Entity(
    "order",
    { orderItems: [orderItem] },
    { idAttribute: "order_id" },
    { order_notes: "order_notes" },
    { user_id: "user_id" }
  );

  const flattenedResult = normalize(result, flattenedOrder);

  return flattenedResult;
};

export const { clearOrderData } = adminSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const adminOrderItemList = (state) => state.admin.adminOrderItemData;
export const adminOrderData = (state) => state.admin.adminOrderData;
export const adminCustomItemData = (state) => state.admin.adminCustomItemData;
export const adminOrderCounts = (state) => state.admin.adminOrderCounts;
export const pendingUserCount = (state) => state.admin.pendingUserCount;
export const usersList = (state) => state.admin.userData;
export const isLoading = (state) => state.admin.loading;
export const error = (state) => state.admin.error;

export default adminSlice.reducer;
