import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getProjectDetails } from "./projectsSlice";
import { itemList } from "../items/projectItemSlice";
import { RoomList } from "../rooms/RoomList";
import { token } from "../users/usersSlice";

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export const Project = (props) => {
  const { id } = props.match.params;
  const tokenVal = useSelector(token);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showHidden, setShowHidden] = useState(false);

  const project = useSelector((state) => state.projects.projectData[id]);
  const projectItems = useSelector(itemList);

  const unOrderedItems = Object.keys(projectItems).some(
    (key) => !projectItems[key].order_date && projectItems[key].status_id !== 2
  );

  useEffect(() => {
    dispatch(getProjectDetails({ tokenVal, id }));
  }, [dispatch, id, tokenVal]);

  const renderComponent = (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.titleText} noWrap>
          {project.name}
        </Typography>

        <FormControlLabel
          control={
            <Switch
              color="primary"
              label="Show Hidden Items"
              className={classes.buttonSpacing}
              onChange={(event) => setShowHidden(event.target.checked)}
            />
          }
          label="Show Hidden Items"
        />

        <Button
          component={Link}
          to={`/checkout/${id}`}
          color="secondary"
          variant="contained"
          aria-label="checkout"
          className={classes.buttonSpacing}
          disabled={!unOrderedItems}
        >
          Place Order
        </Button>
        <Button
          component={Link}
          to={`/room/add/${id}`}
          color="primary"
          variant="contained"
          aria-label="Add Rooms"
          className={classes.buttonSpacing}
        >
          Add Room
        </Button>
      </div>

      {project.rooms && (
        <RoomList roomIds={project.rooms} showHidden={showHidden} />
      )}
    </>
  );

  return renderComponent;
};
