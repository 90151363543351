import React, { useState, useRef, useEffect } from "react";
import { Button, TextField, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { token } from "../users/usersSlice";
import { addProject } from "./projectsSlice";
import { Redirect } from "react-router-dom";
import { shouldRedirect, resetRedirect } from "../redirect/redirectSlice";

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(1),
  },
}));

export const validForm = (fieldData) => {
  const requiredFields =
    fieldData.name &&
    fieldData.street &&
    fieldData.number &&
    fieldData.city &&
    fieldData.postalCode;
  return requiredFields;
};

export const AddProject = () => {
  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const dispatch = useDispatch();
  const selectedInput = useRef();
  const classes = useStyles();

  const [fieldData, setFieldData] = useState({});

  const submitNewProject = (e) => {
    e.preventDefault();
    dispatch(addProject({ tokenVal, addedProject: fieldData }));
  };

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  if (shouldRedirectVal === "projectList") {
    return <Redirect to={"/Projects"} />;
  }

  const renderComponent = (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.titleText} noWrap>
          Add Project
        </Typography>
      </div>
      <form autoComplete="off" onSubmit={submitNewProject}>
        <div>
          <TextField
            inputRef={selectedInput}
            required
            label="Project name"
            onChange={(e) =>
              setFieldData({ ...fieldData, name: e.target.value })
            }
            className={classes.spacing}
          ></TextField>
        </div>

        <Box marginTop={5}>
          <Typography variant="h5" className={classes.titleText} noWrap>
            Address
          </Typography>
          <div>
            <TextField
              inputRef={selectedInput}
              required
              label="Number"
              onChange={(e) =>
                setFieldData({ ...fieldData, number: e.target.value })
              }
              className={classes.spacing}
            ></TextField>
          </div>
          <TextField
            inputRef={selectedInput}
            required
            label="Street"
            onChange={(e) =>
              setFieldData({ ...fieldData, street: e.target.value })
            }
            className={classes.spacing}
          ></TextField>

          <div>
            <TextField
              inputRef={selectedInput}
              label="Unit"
              onChange={(e) =>
                setFieldData({ ...fieldData, unit: e.target.value })
              }
              className={classes.spacing}
            ></TextField>
          </div>

          <div>
            <TextField
              inputRef={selectedInput}
              required
              label="City"
              onChange={(e) =>
                setFieldData({ ...fieldData, city: e.target.value })
              }
              className={classes.spacing}
            ></TextField>
          </div>

          <div>
            <TextField
              inputRef={selectedInput}
              required
              label="Postal Code"
              onChange={(e) =>
                setFieldData({ ...fieldData, postalCode: e.target.value })
              }
              className={classes.spacing}
            ></TextField>
          </div>

          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              aria-label="Create Project"
              disabled={!validForm(fieldData)}
              className={classes.spacing}
            >
              Create Project
            </Button>
          </div>
        </Box>
      </form>
    </>
  );

  return renderComponent;
};
