import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { role } from "../users/usersSlice";

export const AdminRoute = ({ component: Component, ...rest }) => {
  const userRole = useSelector(role);

  return (
    <Route
      {...rest}
      render={(props) =>
        userRole === "Admin" ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};
