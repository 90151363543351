import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  TableContainer,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import { updateUser, updatePassword, token } from "./usersSlice";

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textInputs: {
    width: 200,
    marginTop: theme.spacing(1),
  },
}));

const UserInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fieldData, setFieldData] = useState({});
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
  });
  const tokenVal = useSelector(token);

  const user = useSelector((state) => state.users.user);
  const infoMessage = useSelector((state) => state.users.infoMessage);
  const passwordMessage = useSelector((state) => state.users.passwordMessage);
  const selectedInput = useRef();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  }, []);

  const updateProfileSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateUser({
        tokenVal,
        updatedUserVal: { ...user, ...fieldData },
      })
    );
  };

  const updatePasswordSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updatePassword({
        tokenVal,
        updatePasswordVal: {
          userId: user.user_id,
          oldPassword: passwordData.old_password,
          newPassword: passwordData.new_password,
        },
      })
    );
    setPasswordData({ old_password: "", new_password: "" });
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }))(TableCell);

  const renderComponent = (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.titleText} noWrap>
          My Profile
        </Typography>
      </div>
      <form autoComplete="off" onSubmit={updateProfileSubmit}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell colSpan={2} variant="head">
                  Personal
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Email</TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">First Name</TableCell>
                <TableCell>
                  <TextField
                    defaultValue={user.first_name}
                    className={classes.textInputs}
                    required
                    onChange={(e) =>
                      setFieldData({ ...fieldData, first_name: e.target.value })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Last Name</TableCell>
                <TableCell>
                  <TextField
                    defaultValue={user.last_name}
                    className={classes.textInputs}
                    required
                    onChange={(e) =>
                      setFieldData({ ...fieldData, last_name: e.target.value })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={2} variant="head">
                  Address
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Line 1</TableCell>
                <TableCell>
                  <TextField
                    defaultValue={user.line_1}
                    className={classes.textInputs}
                    required
                    onChange={(e) =>
                      setFieldData({
                        ...fieldData,
                        line_1: e.target.value,
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Line 2</TableCell>
                <TableCell>
                  <TextField
                    defaultValue={user.line_2}
                    className={classes.textInputs}
                    onChange={(e) =>
                      setFieldData({
                        ...fieldData,
                        line_2: e.target.value,
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">City</TableCell>
                <TableCell>
                  <TextField
                    defaultValue={user.city}
                    className={classes.textInputs}
                    required
                    onChange={(e) =>
                      setFieldData({ ...fieldData, city: e.target.value })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Province</TableCell>
                <TableCell>
                  <TextField
                    defaultValue={user.province}
                    className={classes.textInputs}
                    required
                    onChange={(e) =>
                      setFieldData({
                        ...fieldData,
                        province: e.target.value,
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Update My Profile"
            className={classes.buttonSpacing}
            disabled={Object.keys(fieldData).length === 0}
          >
            Update My Profile
          </Button>
          {infoMessage && <Typography>{infoMessage}</Typography>}
        </div>
      </form>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.titleText} noWrap>
          Change Password
        </Typography>
      </div>
      <form autoComplete="off" onSubmit={updatePasswordSubmit}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Old Password</TableCell>
                <TableCell>
                  <TextField
                    className={classes.textInputs}
                    value={passwordData.old_password}
                    type="password"
                    required
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        old_password: e.target.value,
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">New Password</TableCell>
                <TableCell>
                  <TextField
                    className={classes.textInputs}
                    value={passwordData.new_password}
                    required
                    type="password"
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        new_password: e.target.value,
                      })
                    }
                  ></TextField>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Change Password"
            className={classes.buttonSpacing}
            disabled={!passwordData.old_password || !passwordData.new_password}
          >
            Change Password
          </Button>
          {passwordMessage && <Typography>{passwordMessage}</Typography>}
        </div>
      </form>
    </>
  );

  return renderComponent;
};

export default UserInfo;
