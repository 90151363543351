import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  isLoading,
  error,
  createSupplier,
  updateSupplier,
  discontinueSupplier,
  supplierList,
} from "./supplierSlice";
import { token } from "../../users/usersSlice";
import { shouldRedirect, resetRedirect } from "../../redirect/redirectSlice";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
  },
  nameTextBox: {
    marginTop: "20px",
  },
  button: {
    margin: "15px 0",
  },
}));

export const Supplier = (props) => {
  const { id } = props.match.params;
  const { edit } = props;
  const classes = useStyles();

  const tokenVal = useSelector(token);
  const shouldRedirectVal = useSelector(shouldRedirect);
  const dispatch = useDispatch();

  const loading = useSelector(isLoading);
  const hasError = useSelector(error);

  const supplier = useSelector((state) => state.supplier.suppliers[id]);
  const allSuppliers = useSelector(supplierList);

  const [fieldData, setFieldData] = useState({});
  const [duplicateSupplierName, setDuplicateSupplierName] = useState(false);

  const validForm = (fieldData) => {
    return supplier
      ? (fieldData.name && fieldData.name !== supplier.name) ||
          fieldData.accountNumber
      : fieldData.name;
  };

  useEffect(() => {
    return () => {
      dispatch(resetRedirect()); // reset redirect on unmount
    };
  }, [shouldRedirectVal, dispatch]);

  const submitSupplier = (e) => {
    e.preventDefault();

    if (
      fieldData.name &&
      Object.keys(allSuppliers).some(
        (s) =>
          allSuppliers[s].name.toLowerCase() === fieldData.name.toLowerCase()
      )
    ) {
      setDuplicateSupplierName(true);
    } else {
      if (edit) {
        dispatch(
          updateSupplier({
            tokenVal,
            supplier: {
              oldName: supplier.name,
              newName: fieldData.name ?? null,
              accountNumber: fieldData.accountNumber,
              supplier_id: id,
            },
          })
        );
      } else {
        dispatch(
          createSupplier({
            tokenVal,
            supplier: {
              supplierName: fieldData.name,
              accountNumber: fieldData.accountNumber,
            },
          })
        );
      }
      setFieldData({});
    }
  };

  if (shouldRedirectVal === "manageSuppliers") {
    return <Redirect to={`/admin/manageSuppliers`} />;
  }

  let renderComponent = "";

  if (loading === "pending") {
    renderComponent = <CircularProgress />;
  } else {
    renderComponent = (
      <div>
        <Typography variant="h4" className={classes.titleText} noWrap>
          {edit ? supplier.name : "New Supplier"}
        </Typography>
        {hasError && <div>Something went wrong!</div>}
        <form autoComplete="off" onSubmit={submitSupplier}>
          <div>
            <TextField
              className={classes.nameTextBox}
              required
              label="Supplier name"
              error={duplicateSupplierName}
              helperText={
                duplicateSupplierName && "Supplier name already exists"
              }
              onChange={(e) => {
                setFieldData({ ...fieldData, name: e.target.value });
                setDuplicateSupplierName(false);
              }}
              defaultValue={edit ? supplier.name : ""}
            ></TextField>
          </div>
          <div>
            <TextField
              className={classes.nameTextBox}
              required
              label="Account Number"
              onChange={(e) => {
                setFieldData({ ...fieldData, accountNumber: e.target.value });
              }}
              defaultValue={edit ? supplier.account_number : ""}
            ></TextField>
          </div>
          <div>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              aria-label={edit ? "Update Supplier" : "Add Supplier"}
              disabled={!validForm(fieldData) || duplicateSupplierName}
            >
              {edit ? "Update Supplier" : "Add Supplier"}
            </Button>
          </div>
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              aria-label="Discontinue Supplier"
              disabled={supplier && supplier.discontinued}
              onClick={() => {
                const updateCheck = window.confirm;

                if (
                  updateCheck(
                    `Are you sure you want to discontinue all of the ${supplier.name} items?`
                  )
                ) {
                  dispatch(discontinueSupplier({ tokenVal, supplierId: id }));
                }
              }}
            >
              Discontinue Supplier
            </Button>
          </div>
          {supplier && supplier.discontinued && (
            <Typography className={classes.spacing}>
              All items for this supplier have been discontinued.
            </Typography>
          )}
        </form>
      </div>
    );
  }

  return renderComponent;
};
