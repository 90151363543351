import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { PrivateRoute } from "./PrivateRoute";
import { AdminRoute } from "./AdminRoute";
import { ProjectList } from "../projects/ProjectList";
import { Project } from "../projects/Project";
import { AddProject } from "../projects/AddProject";
import { Room } from "../rooms/Room";
import { Window } from "../windows/Window";
import { Register } from "../register/Register";
import { Login } from "../login/Login";
import { ProjectItemDetails } from "../items/ProjectItemDetails";
import { ProductItemDetails } from "../items/ProductItemDetails";
import { PurchaseOrders } from "../orders/PurchaseOrders";
import { PurchaseOrderDetails } from "../orders/PurchaseOrderDetails";
import { Supplier } from "../admin/manageSuppliers/Supplier";
import { ICFYWorkOrderPrintWrapper } from "../admin/workOrders/ICFYWorkOrder";
import UserInfo from "../users/UserInfo";

export const Routes = React.memo(() => {
  const AddItem = lazy(() => import("../items/AddItem"));
  const FabricSearch = lazy(() =>
    import("../items/customItems/fabric/FabricSearch")
  );
  const CustomItemFlow = lazy(() =>
    import("../items/customItems/CustomItemFlow")
  );
  const Checkout = lazy(() => import("../checkout/Checkout"));
  const ConfirmationContainer = lazy(() =>
    import("../confirmation/ConfirmationContainer")
  );
  const SearchContainer = lazy(() => import("../search/SearchContainer"));
  const Admin = lazy(() => import("../admin/Admin"));
  const PendingOrders = lazy(() =>
    import("../admin/pendingOrders/PendingOrders")
  );
  const PendingOrderDetails = lazy(() =>
    import("../admin/pendingOrders/PendingOrderDetails")
  );
  const PendingDepositOrders = lazy(() =>
    import("../admin/pendingDepositOrders/PendingDepositOrders")
  );

  const PendingDepositOrderDetails = lazy(() =>
    import("../admin/pendingDepositOrders/PendingDepositOrderDetails")
  );

  const ApprovedOrders = lazy(() =>
    import("../admin/approvedOrders/ApprovedOrders")
  );
  const ApprovedOrderDetails = lazy(() =>
    import("../admin/approvedOrders/ApprovedOrderDetails")
  );

  const PDFGeneratedOrders = lazy(() =>
    import("../admin/pdfGeneratedOrders/PDFGeneratedOrders")
  );
  const PDFGeneratedICFY = lazy(() =>
    import("../admin/pdfGeneratedIcfy/PDFGeneratedICFY")
  );
  const PDFGeneratedOrderDetails = lazy(() =>
    import("../admin/pdfGeneratedOrders/PDFGeneratedOrderDetails")
  );
  const RejectedOrders = lazy(() =>
    import("../admin/rejectedOrders/RejectedOrders")
  );
  const RejectedOrderDetails = lazy(() =>
    import("../admin/rejectedOrders/RejectedOrderDetails")
  );
  const UploadPriceListFileSelector = lazy(() =>
    import("../admin/uploadPriceList/UploadPriceListFileSelector")
  );
  const ManageSuppliers = lazy(() =>
    import("../admin/manageSuppliers/ManageSuppliers")
  );
  const UsersList = lazy(() => import("../admin/users/UsersList"));
  const User = lazy(() => import("../admin/users/User"));

  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        <Route exact path="/register">
          <Register />
        </Route>
        <PrivateRoute
          path="/projects"
          component={(props) => <ProjectList {...props} />}
        />
        <PrivateRoute
          path="/project/add"
          component={(props) => <AddProject {...props} />}
        />
        <PrivateRoute
          path="/project/:id"
          component={(props) => <Project {...props} />}
        />
        <PrivateRoute
          path="/room/add/:id"
          component={(props) => <Room {...props} edit={false} />}
        />
        <PrivateRoute
          path="/room/edit/:id"
          component={(props) => <Room {...props} edit={true} />}
        />
        <PrivateRoute
          path="/window/add/:id"
          component={(props) => <Window {...props} edit={false} />}
        />
        <PrivateRoute
          path="/window/edit/:id"
          component={(props) => <Window {...props} edit={true} />}
        />
        <PrivateRoute
          path="/projectItem/view/:id"
          component={(props) => <ProjectItemDetails {...props} />}
        />
        <PrivateRoute
          path="/productItem/view/:id"
          component={(props) => <ProductItemDetails {...props} />}
        />
        <PrivateRoute
          exact
          path="/browse"
          component={(props) => <SearchContainer {...props} edit={true} />}
        />
        <PrivateRoute
          path="/search"
          component={(props) => <AddItem {...props} edit={true} />}
        />
        <PrivateRoute
          path="/fabric-search"
          component={(props) => <FabricSearch {...props} edit={true} />}
        />
        <PrivateRoute
          path="/custom-item"
          component={(props) => <CustomItemFlow {...props} edit={true} />}
        />
        <PrivateRoute
          path="/checkout/:id"
          component={(props) => <Checkout {...props} />}
        />
        <PrivateRoute
          path="/confirmation/:id"
          component={(props) => <ConfirmationContainer {...props} />}
        />
        <PrivateRoute
          path="/purchaseorders/"
          component={(props) => <PurchaseOrders {...props} />}
        />
        <PrivateRoute
          path="/purchaseorderdetails/:id"
          component={(props) => <PurchaseOrderDetails {...props} />}
        />
        <PrivateRoute path="/userInfo" component={() => <UserInfo />} />
        <AdminRoute
          exact
          path="/admin"
          component={(props) => <Admin {...props} />}
        />
        <AdminRoute
          path="/admin/pending-orders"
          component={(props) => <PendingOrders {...props} />}
        />
        <AdminRoute
          path="/admin/icfy-work-order/:id"
          component={(props) => <ICFYWorkOrderPrintWrapper {...props} />}
        />
        <AdminRoute
          path="/admin/pending-order-details/:id"
          component={(props) => <PendingOrderDetails {...props} />}
        />
        <AdminRoute
          path="/admin/pending-deposit-orders"
          component={(props) => <PendingDepositOrders {...props} />}
        />
        <AdminRoute
          path="/admin/pending-deposit-order-details/:id"
          component={(props) => <PendingDepositOrderDetails {...props} />}
        />
        <AdminRoute
          path="/admin/approved-orders"
          component={(props) => <ApprovedOrders {...props} />}
        />
        <AdminRoute
          path="/admin/approved-order-details/:id"
          component={(props) => <ApprovedOrderDetails {...props} />}
        />
        <AdminRoute
          path="/admin/pdf-generated-orders"
          component={(props) => <PDFGeneratedOrders {...props} />}
        />
        <AdminRoute
          path="/admin/pdf-generated-icfy-work-orders"
          component={(props) => <PDFGeneratedICFY {...props} />}
        />
        <AdminRoute
          path="/admin/pdf-generated-order-details/:id"
          component={(props) => <PDFGeneratedOrderDetails {...props} />}
        />
        <AdminRoute
          path="/admin/rejected-orders"
          component={(props) => <RejectedOrders {...props} />}
        />
        <AdminRoute
          path="/admin/rejected-order-details/:id"
          component={(props) => <RejectedOrderDetails {...props} />}
        />
        <AdminRoute
          path="/admin/uploadPriceListFileSelector"
          component={(props) => <UploadPriceListFileSelector />}
        />
        <AdminRoute
          path="/admin/manageSuppliers"
          component={(props) => <ManageSuppliers />}
        />
        <PrivateRoute
          path="/supplier/add"
          component={(props) => <Supplier {...props} edit={false} />}
        />
        <PrivateRoute
          path="/supplier/edit/:id"
          component={(props) => <Supplier {...props} edit={true} />}
        />
        <AdminRoute path="/admin/users" component={(props) => <UsersList />} />
        <AdminRoute
          path="/admin/user-details/:id"
          component={(props) => <User {...props} />}
        />
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Suspense>
  );
});
