import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isLoading, roomList } from "./roomSlice";
import { ItemList } from "../items/ItemList";
import { WindowList } from "../windows/WindowList";

const useStyles = makeStyles((theme) => ({
  accordionHeading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    flexGrow: 1,
  },
  accordionSpacing: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  buttonSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  windowHeading: {
    fontSize: theme.typography.pxToRem(20),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export const RoomList = (props) => {
  const classes = useStyles();

  const { roomIds, showHidden } = props;

  const loading = useSelector(isLoading);
  const rooms = useSelector(roomList);

  const projectRooms = roomIds.map((key) => rooms[key]);

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <>
        {projectRooms &&
          projectRooms.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
              ? -1
              : 0
          ) &&
          projectRooms.map(
            (room) =>
              room.room_id && (
                <React.Fragment key={room.room_id}>
                  <Accordion
                    TransitionProps={{ unmountOnExit: true }}
                    className={classes.accordionSpacing}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.accordionHeading}>
                        {room.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-start"
                        >
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Add Items"
                            component={Link}
                            to={`/search?roomId=${room.room_id}`}
                            className={classes.buttonSpacing}
                          >
                            Add Items
                          </Button>
                          <Button
                            component={Link}
                            to={`/custom-item?roomId=${room.room_id}`}
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Add custom item"
                            className={classes.buttonSpacing}
                          >
                            Add Custom Item
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Add Window"
                            component={Link}
                            to={`/window/add/${room.room_id}`}
                            className={classes.buttonSpacing}
                          >
                            Add Window
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            aria-label="Edit Room"
                            component={Link}
                            to={`/room/edit/${room.room_id}`}
                            className={classes.buttonSpacing}
                          >
                            Edit Room
                          </Button>
                        </Grid>

                        {room.items.length > 0 ? (
                          <ItemList
                            itemIds={room.items}
                            showHidden={showHidden}
                          />
                        ) : (
                          <Typography variant="body2">No items</Typography>
                        )}
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          {room.windows && room.windows.some((w) => w) && (
                            <>
                              <Typography className={classes.windowHeading}>
                                {room.name} windows
                              </Typography>
                              <WindowList
                                windowIds={room.windows}
                                showHidden={showHidden}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
              )
          )}
      </>
    );

  return renderComponent;
};
