import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const addWindow = createAsyncThunk(
  "windows/addWindow",
  async (data, { dispatch }) => {
    const { tokenVal, id, roomWindow } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newWindow = await fetch(
        `${process.env.REACT_APP_API_URL}/createWindow`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify({ name: roomWindow.name, room_id: id }),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("createWindow failed");
        } else {
          return res.json();
        }
      });

      return {
        window_id: newWindow.insertId,
        name: roomWindow.name,
        room_id: id,
      };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const editWindow = createAsyncThunk(
  "windows/editWindow",
  async (data, { dispatch }) => {
    const { tokenVal, id, roomWindow } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateWindow`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify({ name: roomWindow.name, window_id: id }),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("editWindow failed");
        }
      });

      return { window_id: id, name: roomWindow.name };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const windowsSlice = createSlice({
  name: "windows",
  initialState: {
    windowData: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [addWindow.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.windowData[action.payload.window_id] = {
        ...action.payload,
        items: [], //add empty items array so that added items can be pushed
      };
    },
    [addWindow.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [addWindow.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [editWindow.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.windowData[action.payload.window_id] = {
        ...state.windowData[action.payload.window_id],
        ...action.payload,
      };
    },
    [editWindow.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [editWindow.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    "projects/getProjectDetails/fulfilled": (state, action) => {
      state.windowData = action.payload.entities.windows || {};
    },
    "productItems/addItemToWindow/fulfilled": (state, action) => {
      state.windowData[action.payload.window_id].items.push(
        action.payload.project_product_id
      );
    },
  },
});

export const windowList = (state) => state.windows.windowData;
export const isLoading = (state) => state.windows.loading;
export const error = (state) => state.windows.error;

export default windowsSlice.reducer;
