import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { normalize, schema } from "normalizr";

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (data, { dispatch }) => {
    const { tokenVal, userId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const orders = await fetch(
        `${process.env.REACT_APP_API_URL}/getOrdersByUser/${userId}`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              dispatch({ type: "invalidToken" });
              throw new Error("invalid token");
            }

            throw new Error("getOrders failed");
          } else {
            return res.json();
          }
        })
        .then((result) => {
          return flattenOrders(result);
        });

      return orders;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "orders/getOrderDetails",
  async (data, { dispatch }) => {
    const { tokenVal, orderId } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const orderDetails = await fetch(
        `${process.env.REACT_APP_API_URL}/getOrderDetails/${orderId}`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            if (res.status === 401) {
              dispatch({ type: "invalidToken" });
              throw new Error("invalid token");
            }

            throw new Error("getOrderDetails failed");
          } else {
            return res.json();
          }
        })
        .then((result) => {
          //organize it like this so normalizr can figure out the relationships
          return flattenOrderItems({
            order_id: orderId,
            order_notes: result.order_notes,
            user_id: result.user_id,
            orderItems: result.items,
          });
        });

      return { orderId, results: orderDetails };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const orderSlice = createSlice({
  name: "orders",
  initialState: {
    orderData: {},
    orderItemData: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [getOrders.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.orderData = action.payload.entities.orders;
    },
    [getOrders.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getOrders.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      const orderId = action.payload.results.result;
      const newOrderData = action.payload.results.entities.order[orderId];

      state.loading = "idle";
      state.error = "";
      state.orderData[orderId] = {
        ...newOrderData,
        orderItems: newOrderData.orderItems,
      };
      state.orderItemData = action.payload.results.entities.orderItems;
    },
    [getOrderDetails.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getOrderDetails.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
  },
});

const flattenOrders = (result) => {
  const order = new schema.Entity("orders", {}, { idAttribute: "order_id" });
  const orders = new schema.Array(order);

  const flattenedResult = normalize(result, orders);

  return flattenedResult;
};

const flattenOrderItems = (result) => {
  const orderItem = new schema.Entity(
    "orderItems",
    {},
    { idAttribute: "project_product_id" }
  );

  const flattenedOrder = new schema.Entity(
    "order",
    { orderItems: [orderItem] },
    { idAttribute: "order_id" },
    { order_notes: "order_notes" },
    { user_id: "user_id" }
  );

  const flattenedResult = normalize(result, flattenedOrder);

  return flattenedResult;
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export const orderDetails = (state) => state.orders.orderData;
export const orderItemList = (state) => state.orders.orderItemData;
export const isLoading = (state) => state.orders.loading;
export const error = (state) => state.orders.error;

export default orderSlice.reducer;
