import { TextField, Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useRef, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthToken, token, authenticationError } from "../users/usersSlice";

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(1),
  },
}));

export const validForm = (fieldData) => {
  return fieldData.email && fieldData.password;
};

export function Login() {
  const [fieldData, setFieldData] = useState({});
  const selectedInput = useRef();
  const dispatch = useDispatch();
  const authenticated = useSelector(token);
  const error = useSelector(authenticationError);
  const classes = useStyles();

  useEffect(() => {
    selectedInput.current && selectedInput.current.focus();
  }, []);

  const login = (e) => {
    e.preventDefault();

    dispatch(getAuthToken({ ...fieldData }));
  };

  const renderComponent = !authenticated ? (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h3" noWrap>
        Stride Studio
      </Typography>

      <Box m={5}>
        <Typography variant="h4" noWrap>
          Login
        </Typography>
        <form autoComplete="off" onSubmit={login}>
          <div>
            <TextField
              variant="outlined"
              className={classes.spacing}
              required
              label="Email"
              onChange={(e) =>
                setFieldData({ ...fieldData, email: e.target.value })
              }
              inputRef={selectedInput}
            ></TextField>
          </div>
          <div>
            <TextField
              variant="outlined"
              className={classes.spacing}
              required
              label="Password"
              type="password"
              onChange={(e) =>
                setFieldData({ ...fieldData, password: e.target.value })
              }
            ></TextField>
          </div>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              aria-label="Login"
              disabled={!validForm(fieldData)}
              className={classes.spacing}
            >
              Login
            </Button>
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
        <Button
          component={Link}
          to="/register"
          color="primary"
          className={classes.spacing}
        >
          Register
        </Button>
      </Box>
    </div>
  ) : (
    <Redirect to="/projects" />
  );

  return renderComponent;
}
