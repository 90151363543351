import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAuthToken = createAsyncThunk(
  "users/getAuthToken",
  async (data) => {
    const headers = new Headers({
      "Content-Type": "application/json",
    });

    try {
      const token = await fetch(`${process.env.REACT_APP_API_URL}/authorize`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((result) => {
          return result ?? Promise.reject;
        });

      return token;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (data, { dispatch }) => {
    const { tokenVal, updatedUserVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateUser`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(updatedUserVal),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("update failed");
        }
      });

      return updatedUserVal;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (data) => {
    const { tokenVal, updatePasswordVal } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updatePassword`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(updatePasswordVal),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            throw new Error("wrong password");
          }

          throw new Error("update failed");
        }
      });

      return true;
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    loading: "",
    error: null,
    infoMessage: null,
    passwordMessage: null,
    authToken: null,
    user_id: null,
    role: null,
    user: null,
  },
  reducers: {
    logout: (state, action) => {},
  },
  extraReducers: {
    [getAuthToken.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.authToken = action.payload.accessToken;
      state.user_id = action.payload.user_id;
      state.role = action.payload.role;
      state.user = action.payload.user;
    },
    [getAuthToken.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [getAuthToken.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = "Login failed. Check your username and password.";
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.infoMessage = "User details updated successfully.";
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    [updateUser.pending]: (state, action) => {
      state.loading = "pending";
      state.infoMessage = "";
    },
    [updateUser.rejected]: (state, action) => {
      state.loading = "idle";
      state.infoMessage = "User info update failed.";
    },
    [updatePassword.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.passwordMessage = "Password updated successfully.";
    },
    [updatePassword.pending]: (state, action) => {
      state.loading = "pending";
      state.passwordMessage = "";
    },
    [updatePassword.rejected]: (state, action) => {
      state.loading = "idle";
      state.passwordMessage = "Password update failed. Try again.";
    },
  },
});

export const { logout } = usersSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const token = (state) => state.users.authToken;
export const authenticationError = (state) => state.users.error;
export const user_id = (state) => state.users.user_id;
export const role = (state) => state.users.role;

export default usersSlice.reducer;
