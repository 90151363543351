import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const addRoom = createAsyncThunk(
  "rooms/addRoom",
  async (data, { dispatch }) => {
    const { tokenVal, id, room } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      const newRoom = await fetch(
        `${process.env.REACT_APP_API_URL}/createRoom`,
        {
          method: "POST",
          mode: "cors",
          headers: headers,
          body: JSON.stringify({ name: room.name, project_id: id }),
        }
      ).then((res) => {
        if (res.status !== 201) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("createRoom failed");
        } else {
          return res.json();
        }
      });

      return { room_id: newRoom.insertId, name: room.name, project_id: id };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const editRoom = createAsyncThunk(
  "rooms/editRoom",
  async (data, { dispatch }) => {
    const { tokenVal, id, room } = data;

    const headers = new Headers({
      "Content-Type": "application/json",
      authorization: `bearer ${tokenVal}`,
    });

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/updateRoom`, {
        method: "PUT",
        mode: "cors",
        headers: headers,
        body: JSON.stringify({ name: room.name, room_id: id }),
      }).then((res) => {
        if (res.status !== 200) {
          if (res.status === 401) {
            dispatch({ type: "invalidToken" });
            throw new Error("invalid token");
          }

          throw new Error("editRoom failed");
        }
      });

      return { room_id: id, name: room.name };
    } catch (err) {
      throw new Error(err.message);
    }
  }
);

export const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    roomData: {},
    loading: "idle",
    error: null,
  },
  extraReducers: {
    [addRoom.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.roomData[action.payload.room_id] = {
        ...action.payload,
        items: [], //add empty items array so that added items can be pushed
      };
    },
    [addRoom.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [addRoom.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    [editRoom.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = "";
      state.roomData[action.payload.room_id] = {
        ...state.roomData[action.payload.room_id],
        ...action.payload,
      };
    },
    [editRoom.pending]: (state, action) => {
      state.loading = "pending";
      state.error = "";
    },
    [editRoom.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.error.message;
    },
    "projects/getProjectDetails/fulfilled": (state, action) => {
      state.roomData = action.payload.entities.rooms || {};
    },
    "windows/addWindow/fulfilled": (state, action) => {
      state.roomData[action.payload.room_id].windows.push(
        action.payload.window_id
      );
    },
    "productItems/addItemToRoom/fulfilled": (state, action) => {
      state.roomData[action.payload.room_id].items.push(
        action.payload.project_product_id
      );
    },
  },
});

export const roomList = (state) => state.rooms.roomData;
export const isLoading = (state) => state.rooms.loading;
export const error = (state) => state.rooms.error;

export default roomsSlice.reducer;
