import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  CircularProgress,
  Link,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isLoading, itemList } from "./projectItemSlice";
import { Currency } from "../../shared/currency/Currency";
import {
  getCustomItemDisplayName,
  getQuantityFormat,
} from "../../shared/Format";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHeading: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

export const ItemList = (props) => {
  const { itemIds, showHidden } = props;
  const loading = useSelector(isLoading);
  const allItems = useSelector(itemList);
  const classes = useStyles();

  const items = itemIds
    .map((itemId) => {
      return {
        ...allItems[itemId],
        wholesale_price: allItems[itemId].wholesale_price
          ? allItems[itemId].wholesale_price
          : allItems[itemId].quote,
      };
    })
    .filter((item) => item.status_id === (showHidden ? 2 : 1));

  const renderComponent =
    loading === "pending" ? (
      <CircularProgress />
    ) : (
      <>
        {items.length > 0 ? (
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="item table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.tableHeading}>
                    Item Name
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableHeading}
                    align="center"
                  >
                    Last Ordered
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableHeading}
                    align="right"
                  >
                    Quantity
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableHeading}
                    align="right"
                  >
                    Unit Price
                  </StyledTableCell>
                  <StyledTableCell
                    className={classes.tableHeading}
                    align="right"
                  >
                    Total
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items &&
                  items.map((item) => (
                    <TableRow key={item.project_product_id}>
                      <TableCell component="th" scope="row">
                        <Typography>
                          <Link
                            component={RouterLink}
                            to={`/projectItem/view/${item.project_product_id}`}
                          >
                            {item.name || getCustomItemDisplayName(item)}
                          </Link>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {item.order_id && (
                          <Typography>
                            <Link
                              component={RouterLink}
                              to={`/purchaseorderdetails/${item.order_id}`}
                            >
                              PO #{item.order_id}
                              {" - "}
                              {new Date(
                                item.order_date * 1000
                              ).toLocaleDateString()}
                            </Link>
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {getQuantityFormat(item.quantity)}
                      </TableCell>
                      <TableCell align="right">
                        {item.wholesale_price ? (
                          <Currency number={item.wholesale_price} />
                        ) : (
                          "--"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {item.wholesale_price ? (
                          <Currency
                            number={item.wholesale_price * item.quantity}
                          />
                        ) : (
                          "--"
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2">No items</Typography>
        )}
      </>
    );

  return renderComponent;
};
