import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { token, user_id } from "../users/usersSlice";
import { getOrders, orderDetails } from "./orderslice";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
  },
  header: { display: "flex" },
  titleText: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
}));

export const PurchaseOrders = (props) => {
  const tokenVal = useSelector(token);
  const userId = useSelector(user_id);
  const dispatch = useDispatch();

  const orders = useSelector(orderDetails);

  let history = useHistory();

  useEffect(() => {
    dispatch(getOrders({ tokenVal, userId }));
  }, [dispatch, tokenVal, userId]);

  const classes = useStyles1();
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({}))(TableRow);

  const renderComponent = (
    <>
      <Typography variant="h4" className={classes.titleText} noWrap>
        Purchase Orders
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Purchase Orders">
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">PO #</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Project</StyledTableCell>
              <StyledTableCell>PO Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders && Object.keys(orders).length > 0 ? (
              Object.keys(orders)
                .sort((a, b) => orders[b].unix_date - orders[a].unix_date) //order by date desc
                .map((key) => (
                  <StyledTableRow
                    key={orders[key].order_id}
                    hover
                    onClick={() =>
                      history.push(
                        `/purchaseorderdetails/${orders[key].order_id}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <StyledTableCell align="right">
                      {orders[key].order_id}
                    </StyledTableCell>
                    <StyledTableCell>
                      {new Date(orders[key].unix_date * 1000).toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell>{orders[key].name}</StyledTableCell>

                    <StyledTableCell>{orders[key].orderStatus}</StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <StyledTableRow key={"na"}>
                <StyledTableCell colSpan={3} align="center">
                  No Orders Found
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  return renderComponent;
};
